.spinner48 {
  font-size: 48px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.noScrollbar {

}
.noScrollbar::-webkit-scrollbar {
  display: none;
}
